import "./App.css";
import Contact from "./components/Contact";
import Footer from "./components/Footer";
import Header from "./components/Header";
import HeaderText from "./components/HeaderText";
import Live from "./components/Live";
// import OurService from "./components/OurService";
// import Programs from "./components/Programs";

function App() {
	return (
		<div className='bg-theme-primary text-lg'>
			<Header />
			<Live />
			<HeaderText />
			{/* <OurService /> */}
			{/* <Programs /> */}
			<Contact />
			<Footer />
		</div>
	);
}

export default App;
