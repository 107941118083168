import React from 'react'
import Reveal from '../shared/Reveal'

const HeaderText = () => {
    return (
        <div className='bg-white'>
            <div class="custom-shape-divider-left-1709652370 md:block hidden">
                <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
                    <path d="M600,112.77C268.63,112.77,0,65.52,0,7.23V120H1200V7.23C1200,65.52,931.37,112.77,600,112.77Z" class="shape-fill"></path>
                </svg>
            </div>
            <div className='mt-[-36px] flex justify-center flex-col items-center px-7 md:px-24 bg-[#ffffff] relative pt-20 md:pt-0'>

                <div className='w-full flex justify-center'>
                    <Reveal hidden={{ opacity: 0, y: 75 }}
                        visible={{ opacity: 1, y: 0 }} transition={{ duration: 0.75, delay: 0.25 }}>
                        <p className='font-extrabold uppercase text-3xl w-4/5 mx-auto text-center text-[#1E1E1E] pb-5 md:pb-0 first-letter max-w-5xl'>The world’s first Dance Chart produced & presented by AI</p>
                    </Reveal>
                </div>
                <div className='w-full pl-10 flex justify-center'>
                    <Reveal hidden={{ opacity: 0, y: 75 }}
                        visible={{ opacity: 1, y: 0 }} transition={{ duration: 0.75, delay: 0.50 }}>
                        <p className='pt-4  text-gray-600 max-w-7xl w-4/5 mx-auto text-center text-xl'>The AI Dance Chart is the weekly rundown of the worlds’ biggest dance hits. Based on downloads (Beatport, iTunes), streams (Spotify, YouTube), Shazam, airplay data from Radiomonitor.com and plays by leading deejays.

Independently compiled each week by AI RadioPlayer.com</p>
                    </Reveal>
                </div>
            </div>
        </div>
    )
}

export default HeaderText