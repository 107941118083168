import Reveal from "../shared/Reveal";

const people = [

	{
        "title": "THANK YOU",
        "artist": "DIMITRI VEGAS & LIKE MIKE",
        "image": "https://linkstorage.linkfire.com/medialinks/images/5fc76227-b8b3-48ff-aec7-099b0b24c372/artwork-440x440.jpg",
        "week": 1,
        "lastWeek": 5
    },
    {
        "title": "DEEP IN YOUR LOVE",
        "artist": "ALOK, BEBE REXHA",
        "image": "https://globaldancechart.com/wp-content/uploads/2024/01/DEEP-IN-YOUR-LOVE-alok-cover-150x150.jpg",
        "week": 2,
        "lastWeek": 7
    },
    {
        "title": "WHEN WE WERE YOUNG (THE LOGICAL SONG)",
        "artist": "DAVID GUETTA, KIM PETRAS",
		"image": "https://globaldancechart.com/wp-content/uploads/2023/11/WHEN-WE-WERE-YOUNG-THE-LOGICAL-SONG-david-guetta-kim-petras-cover-150x150.jpg",
        "week": 3,
        "lastWeek": 1
    },
    {
        "title": "PERFECT (EXCEEDER)",
        "artist": "DAVID GUETTA, MASON, PRINCESS SUPERSTAR",
        "image": "https://globaldancechart.com/wp-content/uploads/2024/02/PERFECT-EXCEEDER-david-guetta-cover-150x150.jpg",
        "week": 4,
        "lastWeek": 23
    },
    {
        "title": "HEAD DOWN",
        "artist": "LOST FREQUENCIES, BASTILLE",
        "image": "https://globaldancechart.com/wp-content/uploads/2024/01/head-down-lost-frequencies-cover-150x150.jpg",
        "week": 5,
        "lastWeek": 13
    },
    {
        "title": "ALIBI (FEAT. RUDIMENTAL)",
        "artist": "ELLA HENDERSON",
        "image": "https://i.discogs.com/weWoo273dOvUlczgRiGADwjZyb1gqV32YkmT29vnUbs/rs:fit/g:sm/q:90/h:600/w:600/czM6Ly9kaXNjb2dz/LWRhdGFiYXNlLWlt/YWdlcy9SLTI5NDc3/NjUwLTE3MDUxNjg1/OTQtMjQwOC5qcGVn.jpeg",
        "week": 6,
        "lastWeek": 7
    },
    
    {
		"title": "MURDER ON THE DANCEFLOOR",
        "artist": "SOPHIE ELLIS-BEXTOR",
        "image": "https://i.discogs.com/QIq_QVOQftBlEera3vaCr_woAmIOTvNwyNvJJxfKumE/rs:fit/g:sm/q:90/h:600/w:600/czM6Ly9kaXNjb2dz/LWRhdGFiYXNlLWlt/YWdlcy9SLTI5ODA5/My0xNjgzMDgwMTY2/LTc5NzEuanBlZw.jpeg",
        "week": 7,
        "lastWeek": 5
    },
	{
        "title": "FIRE!",
        "artist": "ALAN WALKER, JVKE & YUQI",
        "image": "https://is1-ssl.mzstatic.com/image/thumb/Music126/v4/06/71/62/067162c5-0d43-168d-dcad-3c035cfd7e20/730553306976.png/592x592bb.webp",
        "week": 8,
        "lastWeek": 13
    },
    {
        "title": "ALL NIGHT LONG",
        "artist": "KUNGS, DAVID GUETTA & IZZY BIZU",
        "image": "https://i.discogs.com/yvUg93lSrqdpXhaorULTyzoZDmMuogseGO2i7D28vEc/rs:fit/g:sm/q:90/h:600/w:600/czM6Ly9kaXNjb2dz/LWRhdGFiYXNlLWlt/YWdlcy9SLTI5NTU0/OTYzLTE3MDU4MzYy/MjctNjU5My5qcGVn.jpeg",
        "week": 9,
        "lastWeek": 6
    },
    {
        "title": "SWEET DISPOSITION (JOHN SUMMIT & SILVER PANDA REMIX)",
        "artist": "THE TEMPER TRAP",
        "image": "https://globaldancechart.com/wp-content/uploads/2024/02/SWEET-DISPOSITION-John-Summit-Silver-Panda-remix-John-summit-cover-150x150.jpg",
        "week": 10,
        "lastWeek": 19
    },
    {
        "title": "SKIP",
        "artist": "SEBASTIAN INGROSSO, STEVE ANGELLO",
        "image": "https://globaldancechart.com/wp-content/uploads/2024/03/skip-Sebastian-Ingrosso-Steve-Angello-cover-150x150.jpg",
        "week": 11,
        "lastWeek": 36
    },
    {
        "title": "MYSTERIOUS TIMES",
        "artist": "NICK JAY & JEAN LUC FEAT. SHARON WEST",
        "image": "https://i1.sndcdn.com/artworks-50KXBTH8q0A3VMdo-Au0PKg-t500x500.jpg",
        "week": 12,
        "lastWeek": 9
    },
    {
        "title": "LOVERS IN A PAST LIFE",
        "artist": "CALVIN HARRIS, RAG'N'BONE MAN",
        "image": "https://is1-ssl.mzstatic.com/image/thumb/Music126/v4/11/27/dd/1127dd4a-285f-6295-2da0-3442ad5a3684/196871819862.jpg/225x225bb.jpg",
        "week": 13,
        "lastWeek": 14
    },
    {
        "title": "BEATS FOR THE UNDERGROUND",
        "artist": "MAU P",
        "image": "https://globaldancechart.com/wp-content/uploads/2024/02/BEATS-FOR-THE-UNDERGROUND-mau-p-cover-150x150.jpg",
        "week": 14,
        "lastWeek": 3
    },
    {
        "title": "YOU & ME (RIVO REMIX)",
        "artist": "DISCLOSURE, ELIZA DOOLITTLE",
        "image": "https://globaldancechart.com/wp-content/uploads/2024/01/YOU-ME-Rivo-remix-Disclosure-cover-150x150.jpg",
        "week": 15,
        "lastWeek": 10
    },
    {
        "title": "SAVE ME TONIGHT",
        "artist": "ARTY",
        "image": "https://i.discogs.com/jDC4E5oBI2uxVjFCWxy6Uqi_HsOtvtnmym2vF5mau6I/rs:fit/g:sm/q:90/h:600/w:600/czM6Ly9kaXNjb2dz/LWRhdGFiYXNlLWlt/YWdlcy9SLTE1MzYw/MTk2LTE1OTAyODEx/NjQtNTYyMC5qcGVn.jpeg",
        "week": 16,
        "lastWeek": 16
    },
    {
        "title": "BEAT OF YOUR HEART",
        "artist": "PURPLE DISCO MACHINE, ASDIS",
        "image": "https://globaldancechart.com/wp-content/uploads/2024/02/purple_disco_machine-beat_of_your_heart-150x150.jpg",
        "week": 17,
        "lastWeek": 9
    },
    {
        "title": "WHATEVER",
        "artist": "AVA MAX, KYGO",
        "image": "https://i.discogs.com/X_TGm_48e6vNnQ6q9qMNaO4igrvc3Hh3hNprHCdVAZM/rs:fit/g:sm/q:90/h:600/w:600/czM6Ly9kaXNjb2dz/LWRhdGFiYXNlLWlt/YWdlcy9SLTI5NTMz/NDg2LTE3MDU2NjIx/MjUtMjY3My5qcGVn.jpeg",
        "week": 18,
        "lastWeek": 0
    },
    {
        "title": "CYNICAL",
        "artist": "TWOCOLORS X SAFRI DUO X CHRIS DE SARANDY",
        "image": "https://lh3.googleusercontent.com/Mro2Xvu4kNHl8zGrT3pXHqEm_pV0H7eqte_s8GKykZzEmL0stwKbNoB6DcaXk6YRSHTSagqzVXlxnKYSug",
        "week": 19,
        "lastWeek": 12
    },
    {
        "title": "IN THE CITY",
        "artist": "CHARLIE XCX & SAM SMITH",
        "image": "https://is1-ssl.mzstatic.com/image/thumb/Music126/v4/31/73/33/31733385-0217-e585-2087-35d627fca247/075679665836.jpg/592x592bb.webp",
        "week": 20,
        "lastWeek": 37
    },
  ];

export default function Chart() {
  return (
    <div className=" w-full mt-3 relative">
      <div className="flex flex-col items-start h-full  shadow-md rounded-md overflow-hidden w-full">
        <div className="bg-[#2b0b3d] border-b-2 px-4 py-3 w-full">
          <div className="flex justify-between items-center uppercase ">
            <div className="flex space-x-3">
              <p className="text-sm font-semibold text-white">Week</p>
              <p className="text-sm font-semibold text-white">Name</p>
            </div>
            <div>
              <p className="text-sm font-semibold text-white">Last Week</p>
            </div>
          </div>
        </div>

        <ul className="divide-y divide-gray-700 overflow-y-auto h-80 sm:h-full max-h-[518px] w-full overflow-x-hidden scroll-design">
          {people.map((person, index) => (
            <Reveal key={index}
              hidden={{
                opacity: 0,
                x: index % 2 === 0 ? -75 : 75,
              }}
              visible={{ opacity: 1, x: 0 }}>
              <li
                key={index}
                className={`flex justify-between p-3 hover:bg-purple-200 ${index % 2 === 0 ? "bg-white" : "bg-white"}`}
              >
                <div className="flex items-center gap-3">
                  <p className="text-sm p-2 m-1 font-medium text-white bg-theme-third rounded-full h-[40px] w-[40px] flex items-center justify-center">
                    {String(person.week).padStart(2, "0")}
                  </p>
                  <img
                    className="h-10 w-10 m-1 rounded-full"
                    src={person.image}
                    alt=""
                  />
                  <div className="flex flex-col items-start">
                    <p className="text-sm font-medium text-gray-800">
                      {person.title}
                    </p>
                    <p className="text-sm text-gray-600">{person.artist}</p>
                  </div>
                </div>
                <div className="flex items-center">
                  <p className="text-sm font-medium text-gray-500 ml-2">
                    {person.lastWeek}
                  </p>
                </div>
              </li>
            </Reveal>
          ))}
        </ul>
      </div>
    </div >

  );
}
